<template>
  <v-textarea
      outlined
      :value="value"
      :rules="validator.description"
      :counter="minCharCount"
      @input="onInput"
  />
</template>

<script>

export default {
  name: "CustomTextarea",
  props: [
      "value",
      "minCharCount"
  ],
  data: () => ({
    validator: {}
  }),
  mounted() {
    this.validator = {
      description: [(v => this.ValidateStringLength(v, this.minCharCount, 1024))]
    }
  },
  methods: {
    onInput(event) {
      this.$emit('input', event);
    },
    ValidateStringLength(v, min, max) {
      return (!!v && v.length >= min && v.length <= max) || this.$t('ERR_DESCRIPTION_LENGTH', {count: this.minCharCount});
    }
  }
}
</script>

<i18n>
{
  "en": {
    "ERR_DESCRIPTION_LENGTH": "At least {count} characters."
  }
}
</i18n>
