<template>
  <v-container>
    <v-form :value="formValid" ref="form">
      <v-card rounded :disabled="showDialog">
        <v-card-text>
          <v-row>
            <v-col>
              <div class="headline">{{$t('MSG_WORK_TYPE')}}</div>
              <custom-select
                  v-model="selectedWorkType"
                  :items="workTypes"
                  :label="$t('LBL_SELECT')"
              />
              <div class="headline">{{$t('MSG_WORK_DETAIL')}}</div>
              <custom-textarea
                  v-model="workDetail"
                  min-char-count="20"
              />
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <custom-dialog
              v-model="showDialog"
              :activator-label="$t('LBL_NEW_SESSION')"
              :dialog-title="$t('CONFIRM_START_SESSION')"
              @activate="onSubmit"
              @cancel="onCancel"
              @confirm="onConfirm"
          />
        </v-card-actions>
      </v-card>
    </v-form>
  </v-container>
</template>

<script>
import CustomDialog from '@/components/CustomDialog';
import CustomSelect from '@/components/CustomSelect';
import CustomTextarea from '@/components/CustomTextarea';
import router from '@/router';
export default {
  name: "Attendance",
  components: {CustomDialog, CustomTextarea, CustomSelect},
  $ref: {
    form: HTMLFormElement,
  },
  data: () => ({
    formValid: false,
    showDialog: false,
    workTypes: [],
    selectedWorkType: {},
    workDetail: "",
  }),
  methods: {
    onSubmit() {
      if (this.$refs.form.validate()) {
        this.showDialog = true;
      }
    },
    async onConfirm() {
      //send request
      const result = await this.$store.dispatch("startSession", {
        type: this.selectedWorkType.value,
        details: this.workDetail,
      });
      if (result) {
        await router.replace('/session/stop');
      }
    },
    onCancel() {
      this.showDialog = false;
    },
  },
  mounted() {
    this.workTypes = [
      { text: this.$t("type.session.fix"), value: "b" },
      { text: this.$t("type.session.feature"), value: "f" },
      { text: this.$t("type.session.enhance"), value: "e" },
      { text: this.$t("type.session.doc"), value: "d" },
      { text: this.$t("type.session.research"), value: "r" },
      { text: this.$t("type.session.other"), value: "o" },
    ]
  }
}
</script>

<i18n>
{
  "en": {
    "LBL_SELECT": "Select Work Type",
    "LBL_NEW_SESSION": "Start new session",
    "MSG_WORK_TYPE": "Work type of this session:",
    "MSG_WORK_DETAIL": "What is this session about?",
    "CONFIRM_START_SESSION": "Confirm to start a new session?"
  }
}
</i18n>
